import { Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import airMonitor from "../assets/airMonitor.jpeg";
import homeSecurity from "../assets/homeSecurity.jpeg";
import homeSecurityPlans from "../assets/homeSecurityPlans.png";
import nonlethalTurret from "../assets/nonlethalTurret.jpg";
import spySuit from "../assets/spySuit.jpg";
import stunGloves from "../assets/stunGloves.jpeg";
import turtleneck from "../assets/turtleneck.png";
import useStorage from "../hooks/useStorage";

const PRODUCT_IMG_MAP = {
  "SKU1::VARSKU1": stunGloves,
  "SKU2::VARSKU1": turtleneck,
  "SKU3::VARSKU1": airMonitor,
  "SKU4::VARSKU1": nonlethalTurret,
  "SKU5::VARSKU1": homeSecurity,
  "SKU5::VARSKU2": homeSecurityPlans,
  "SKU6::VARSKU1": spySuit,
};

export default function ProductCard({ productData }) {
  const { addToCart } = useStorage();

  const handleAddItemToCart = () => {
    addToCart(productData);
    toast(
      `Added ${productData.productName}, ${productData.variantName} to cart`,
      {
        theme: "light",
        position: "top-left",
        type: "success",
        closeOnClick: true,
        pauseOnOver: false,
      },
    );
  };

  return (
    <>
      <Card style={{ width: "18rem" }}>
        <Card.Img
          variant="top"
          src={
            PRODUCT_IMG_MAP[
              `${productData.productId}::${productData.variantId}`
            ]
          }
        />
        <Card.Body>
          <Card.Title>{productData.productName}</Card.Title>
          <Card.Text>{productData.variantName}</Card.Text>
          <Card.Text>Price: ${productData.price}</Card.Text>
          <Button variant="primary" onClick={handleAddItemToCart}>
            Add to cart
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}
