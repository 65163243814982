import Navbar from "../Navbar";

export default function ThankYou() {
  return (
    <>
      <Navbar />
      <div className="page">
        <h1>Thank you</h1>
      </div>
    </>
  );
}
