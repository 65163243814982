import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import usePrevious from "./usePrevious";

export default function useLocationListener() {
  const location = useLocation();
  const [initialRenderGate1, setInitialRenderGate1] = useState(false);
  const [initialRenderGate2, setInitialRenderGate2] = useState(false);
  const shouldCallPageView = initialRenderGate1 && initialRenderGate2;
  const prevShouldCallPageView = usePrevious(shouldCallPageView);

  const callNorthbeam = (fn) => {
    if (window.Northbeam) {
      fn();
    } else {
      window.onNorthbeamLoad = fn;
    }
  };

  useEffect(() => {
    setInitialRenderGate1(true);
  }, []);

  useEffect(() => {
    if (shouldCallPageView && prevShouldCallPageView) {
      callNorthbeam(() => window.Northbeam.trackPageView());
    }
    setInitialRenderGate2(true);
  }, [shouldCallPageView, prevShouldCallPageView, location]);
}
