import React from "react";

import ProductCard from "./components/productCard";
import products from "./data/products.json";
import Navbar from "./Navbar";

import "./App.css";

function App() {
  const randomNumber = Math.floor(Math.random() * products.length);

  return (
    <>
      <Navbar />
      <div className="page">
        <h1>Welcome to Shute Industries</h1>
        <div className="mt-40">
          <p>
            <span className="quote-large">
              {/* eslint-disable-next-line quotes  */}
              {'"You miss 100% of the shots you don\'t take"'}
            </span>
            <span className="ref-small">- Wayne Gretzky</span>
            <span className="quote-large">- Michael Scott</span>
          </p>
        </div>
        <h2>Recommended for you:</h2>
        <ProductCard productData={products[randomNumber]} />
      </div>
    </>
  );
}

export default App;
