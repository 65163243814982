import { atom } from "jotai";

const userEmailAtom = atom(null);
const cartItemsAtom = atom([]);
const cartItemsCountAtom = atom((get) =>
  get(cartItemsAtom)
    .map((item) => item.quantity)
    .reduce((pv, cv) => pv + cv, 0),
);

export { userEmailAtom, cartItemsAtom, cartItemsCountAtom };
