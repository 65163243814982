import beABadass from "../assets/beABadass.png";
import Navbar from "../Navbar";

export default function About() {
  return (
    <>
      <Navbar />
      <div className="page">
        <h1>Shute Industries</h1>
        <div className="flex space-around">
          <img src={beABadass} alt="badass dude" width="25%" />
          <div>
            <p>
              Hi, I am the founder here at Shute Industries. Our motto is be
              serious, be prepared, and be a badass.
            </p>
            <p>
              If you can't keep up, get out. Show yourself some self respect
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
