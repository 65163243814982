import { useState } from "react";

import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import useStorage from "../hooks/useStorage";
import Navbar from "../Navbar";

export default function Login() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { login } = useStorage();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(email);
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <div className="page">
        <h1>Log in plz</h1>
        <div>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={handleEmailChange}
              />
              <Form.Text className="text-muted">
                Hourly newsletter signup with login :)
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}
