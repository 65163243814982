import { useAtom } from "jotai";
import { NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";

import { userEmailAtom, cartItemsCountAtom } from "./atoms";
import useStorage from "./hooks/useStorage";

export default function ShuteNavbar() {
  const [cartItemsCount] = useAtom(cartItemsCountAtom);
  const [userEmail] = useAtom(userEmailAtom);
  const { logout } = useStorage();
  const navigate = useNavigate();

  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case "login":
        navigate("/login");
        break;
      case "logout":
        logout();
        navigate("/");
        break;
      case "cart":
        navigate("/cart");
        break;
      case "about":
        navigate("/about");
        break;
      case "products":
        navigate("/products");
        break;
      case "home":
        navigate("/");
        break;
      default:
        throw new Error(`${eventKey} is not a navbar item`);
    }
  };

  const renderLoggedInNavbarItems = () => (
    <>
      <Nav className="me-auto" onSelect={handleSelect}>
        <Nav.Link eventKey="home">Home</Nav.Link>
        <Nav.Link eventKey="products">Products</Nav.Link>
        <Nav.Link eventKey="about">About</Nav.Link>
      </Nav>
      <Nav onSelect={handleSelect}>
        <Nav.Link eventKey="cart">Cart ({cartItemsCount})</Nav.Link>
        <NavDropdown title={userEmail}>
          <NavDropdown.Item eventKey="logout">Logout</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </>
  );

  const renderLoggedOutNavbarItems = () => (
    <>
      <Nav className="me-auto" onSelect={handleSelect}>
        <Nav.Link eventKey="home">Home</Nav.Link>
        <Nav.Link eventKey="products">Products</Nav.Link>
        <Nav.Link eventKey="about">About</Nav.Link>
      </Nav>
      <Nav onSelect={handleSelect}>
        <Nav.Link eventKey="cart">Cart ({cartItemsCount})</Nav.Link>
        <Nav.Link eventKey="login">Login</Nav.Link>
      </Nav>
    </>
  );

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        {userEmail && userEmail !== "null"
          ? renderLoggedInNavbarItems()
          : renderLoggedOutNavbarItems()}
      </Container>
    </Navbar>
  );
}
