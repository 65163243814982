import { Buffer } from "buffer";

import { useAtom } from "jotai";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { cartItemsAtom } from "../atoms";
import useStorage from "../hooks/useStorage";
import Navbar from "../Navbar";

export default function Cart() {
  const { checkout, removeFromCart } = useStorage();
  const [cartItems] = useAtom(cartItemsAtom);
  const navigate = useNavigate();

  const handleRemoveCartItem = (cartItem) => {
    removeFromCart(cartItem);
    toast(`Successfully removed ${cartItem.productName} from cart`, {
      theme: "light",
      position: "top-left",
      type: "success",
      closeOnClick: true,
      pauseOnOver: false,
    });
  };

  const renderCartItemsForCheckout = () => {
    return cartItems.map((cartItem) => {
      return (
        <div
          className="flex space-between width-75pc margin-auto"
          key={`${cartItem.productId}::${cartItem.variantId}`}
        >
          <div className="p-14 flex space-between">
            <p className="text-bold">
              {cartItem.productName} {cartItem.variantName}
            </p>
            <p className="ml-14">${cartItem.price}</p>
          </div>
          <div>
            <div className="flex space-between text-center align-center">
              <p className="cart-quantity">x {cartItem.quantity}</p>
              <Button
                variant="danger"
                onClick={() => handleRemoveCartItem(cartItem)}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleCheckout = () => {
    if (cartItems.length === 0) {
      toast("You can't checkout nothing you weirdo", {
        theme: "light",
        position: "top-left",
        type: "warning",
        closeOnClick: true,
        pauseOnOver: false,
      });
      return;
    }
    const cartData = checkout();
    const cartDataBuffer = Buffer.from(JSON.stringify(cartData), "utf-8");
    const encodedCartData = cartDataBuffer.toString("base64");
    navigate(`/checkout?cartData=${encodedCartData}`);
  };

  return (
    <>
      <Navbar />
      <div className="page">
        <div className="text-center mb-40">
          <h1>Your Cart</h1>
        </div>
        {renderCartItemsForCheckout()}
        <div className="mt-40 width-75pc">
          <p>
            Subtotal: $
            {cartItems.reduce((mem, cv) => cv.price * cv.quantity + mem, 0)}
          </p>
        </div>
        <div className="mt-40 width-75pc margin-left-auto">
          <div className="float-right">
            <Button onClick={handleCheckout} variant="primary" size="lg">
              Checkout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
