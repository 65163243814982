import { useEffect } from "react";

import { useAtom } from "jotai";

import { userEmailAtom, cartItemsAtom } from "./atoms";

export default function AppSetup() {
  const LOGGED_IN_USER = "email";
  const CART_ITEMS = "cart";
  const [_userEmail, setUserEmail] = useAtom(userEmailAtom);
  const [_cartItems, setCartItems] = useAtom(cartItemsAtom);

  useEffect(() => {
    setUserEmail(localStorage.getItem(LOGGED_IN_USER));
    setCartItems(JSON.parse(localStorage.getItem(CART_ITEMS)) || []);
  }, [setUserEmail, setCartItems]);

  return null;
}
