import ProductCard from "../components/productCard";
import products from "../data/products.json";
import Navbar from "../Navbar";

export default function Products() {
  // console.log(products)

  const renderProducts = () => {
    return products.map((productData) => (
      <div
        className="m14"
        key={`${productData.productId}::${productData.variantId}`}
      >
        <ProductCard productData={productData} />
      </div>
    ));
  };

  return (
    <>
      <Navbar />
      <div className="page">
        <div className="text-center">
          <h1 className="page-title">Products</h1>
        </div>
        <div className="flex space-around flex-wrap">{renderProducts()}</div>
      </div>
    </>
  );
}
