import { useAtom } from "jotai";

import { userEmailAtom, cartItemsAtom } from "../atoms";

const LOGGED_IN_USER = "email";
const CART_ITEMS = "cart";

/* function deepEqualityCheck(arg1, arg2) {
       if(Array.isArray(arg1)){
           if(!Array.isArray(arg2)) { return false }
           if(arg1.length !== arg2.length) { return false }
           for(let i = 0; i < arg1.length; i++){
               const elementsMatch = deepEqualityCheck(arg1[i], arg2[i])
               if(!elementsMatch) { return false }
           }
           return true
       }else if(typeof arg1 === 'object'){
           if(typeof arg2 !== 'object'){
               return false
           }
           const keys1 = Object.keys(arg1)
           const keys2 = new Set(Object.keys(arg2))
           if(keys1.length !== keys2.size) { return false }
           if(keys1.some(key1 => !keys2.has(key1))){ return false}
           for(let i = 0; i < keys1.length; i++){
               if(!deepEqualityCheck(arg1[keys1[i]], arg2[keys1[i]])){ return false}
           }
           return true
       }else{
           return arg1 === arg2
       }
   } */

export default function useStorage() {
  const [_userEmail, setUserEmail] = useAtom(userEmailAtom);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);

  const login = (email) => {
    localStorage.setItem(LOGGED_IN_USER, email);
    window.Northbeam.identify("email", email);
    setUserEmail(email);
  };

  const logout = () => {
    localStorage.setItem(LOGGED_IN_USER, null);
    localStorage.setItem(CART_ITEMS, JSON.stringify([]));
    setUserEmail(null);
    setCartItems([]);
  };

  const addToCart = (cartData) => {
    const prevCartData = JSON.parse(localStorage.getItem(CART_ITEMS)) || [];
    const indexOfItem = prevCartData.findIndex(
      (cartItem) =>
        `${cartItem.productId}::${cartItem.variantId}` ===
        `${cartData.productId}::${cartData.variantId}`,
    );
    if (indexOfItem === -1) {
      localStorage.setItem(
        CART_ITEMS,
        JSON.stringify([...prevCartData, { ...cartData, quantity: 1 }]),
      );
      setCartItems((prevCart) => [...prevCart, { ...cartData, quantity: 1 }]);
    } else {
      const newCartData = [...prevCartData];
      newCartData[indexOfItem].quantity += 1;
      localStorage.setItem(CART_ITEMS, JSON.stringify(newCartData));
      setCartItems([...newCartData]);
    }
    window.Northbeam.fireCustomGoal("add_to_cart", cartData);
  };

  const removeFromCart = (cartData) => {
    const { productId } = cartData;
    const filterProduct = (cartItems) => {
      const currentCart = [...cartItems];
      const indexOfItem = currentCart.findIndex(
        (cartItem) => cartItem.productId === productId,
      );
      if (indexOfItem === -1) {
        return currentCart;
      }
      currentCart[indexOfItem].quantity -= 1;
      return cartItems.filter((cartItem) => cartItem.quantity > 0);
    };
    localStorage.setItem(
      CART_ITEMS,
      JSON.stringify(
        filterProduct(JSON.parse(localStorage.getItem(CART_ITEMS) || [])),
      ),
    );
    setCartItems((prevCart) => filterProduct(prevCart));
  };

  const checkout = () => {
    const cartCheckoutData = cartItems;
    localStorage.setItem(CART_ITEMS, JSON.stringify([]));
    setCartItems([]);
    return cartCheckoutData;
  };

  return {
    login,
    logout,
    addToCart,
    removeFromCart,
    checkout,
  };
}
