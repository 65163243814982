import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import AppSetup from "./appSetup";
import ErrorPage from "./errorPage";
import LocationListener from "./locationListener";
import reportWebVitals from "./reportWebVitals";
import About from "./routes/about";
import Cart from "./routes/cart";
import Checkout from "./routes/checkout";
import Login from "./routes/login";
import Products from "./routes/products";
import ThankYou from "./routes/thankYou";

import "./index.css";

const decorateWithListener = (component) => (
  <LocationListener>{component}</LocationListener>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: decorateWithListener(<App />),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: decorateWithListener(<Login />),
  },
  {
    path: "products",
    element: decorateWithListener(<Products />),
  },
  {
    path: "about",
    element: decorateWithListener(<About />),
  },
  {
    path: "cart",
    element: decorateWithListener(<Cart />),
  },
  {
    path: "checkout",
    element: decorateWithListener(<Checkout />),
  },
  {
    path: "thank-you",
    element: decorateWithListener(<ThankYou />),
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <>
    <AppSetup />
    <RouterProvider router={router} />
    <ToastContainer />
  </>,
  // </React.StrictMode>
);

/* If you want to start measuring performance in your app, pass a function
   to log results (for example: reportWebVitals(console.log))
   or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
