import { Buffer } from "buffer";

import { useAtom } from "jotai";
import { Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { userEmailAtom } from "../atoms";
import Navbar from "../Navbar";

export default function Checkout() {
  const navigate = useNavigate();
  const [userEmail] = useAtom(userEmailAtom);

  const [params] = useSearchParams();
  const encodedCartData = params.get("cartData");
  const cartData = JSON.parse(
    Buffer.from(encodedCartData, "base64").toString("utf-8"),
  );

  const subtotal = cartData.reduce(
    (mem, cv) => mem + cv.quantity * cv.price,
    0,
  );
  const shippingPrice = subtotal * 0.1;
  const taxPrice = 0; // #taxationIsTheft
  const coupons = "";
  const customerId = userEmail ?? uuid();
  const currency = "USD"; // Merica
  const totalPrice = subtotal + shippingPrice + taxPrice;

  const handleConfirmCheckout = () => {
    window.Northbeam.firePurchaseEvent({
      id: uuid(),
      totalPrice,
      shippingPrice,
      taxPrice,
      coupons,
      currency,
      customerId,
      lineItems: cartData,
    });
    navigate("/thank-you");
  };

  return (
    <>
      <Navbar />
      <div className="page">
        <h1>Confirm Checkout</h1>
        <p>We'll find you</p>
        <p>Subtotal: ${subtotal}</p>
        <p>Shipping: ${shippingPrice}</p>
        <p>
          Tax: A body of men holding themselves accountable to nobody ought not
          to be trusted by anybody. $0
        </p>
        <p>Total: ${totalPrice}</p>
        <Button variant="primary" size="lg" onClick={handleConfirmCheckout}>
          Confirm Checkout
        </Button>
      </div>
    </>
  );
}
